import React from "react"
import tesla from "../images/logos/tesla-destination-charging.png"
import sawday from "../images/logos/charter.png"
import tripadvisor from "../images/logos/tripadvisor.svg"

const Affiliates = () => (    
    <div className="affiliates">   
        <a href="https://www.tesla.com/en_GB/destination-charging" rel="noreferrer noopener" target="_blank">
            <img src={tesla} alt="Tesla Destination Charging"/>
        </a>  
        <a href="https://www.tripadvisor.co.uk/VacationRentalReview-g552029-d8059062-House_in_the_Yard_The_Owl_Barn-Newborough_Anglesey_North_Wales_Wales.html" rel="noreferrer noopener" target="_blank">
            <img src={tripadvisor} alt="Trip Advisor Travellors Choice"/>
        </a>
        <a href="https://www.sawdays.co.uk/britain/wales/anglesey/house-in-the-yard/" rel="noreferrer noopener" target="_blank">
            <img src={sawday} alt=" Sawdays Clean and Safe Charter"/>
        </a>
    </div>
)

export default Affiliates
