import React from "react"
import Layout from "../components/layout"
import Testimonials from "../components/testimonials"
import SEO from "../components/seo"
import Image from "../components/image"
import Affiliates from "../components/affiliates"
import Anglesey from "../images/llanddwn.jpg"
import { Link } from "gatsby"

const Home = () => (
  <Layout>
    <SEO title="Welcome to House-in-the-Yard" /> 
    <div className="banner">
      <Image />
      <section style={{fontSize:`20px`, paddingTop: `20px`}}> 
        <div>          
          <h1>Self-Catering Accommodation in Llangaffo<br></br> Isle of Anglesey </h1>   
        </div>
      </section>
    </div>
    <section style={{paddingTop: `20px`}}>
      <p>House-in-the-Yard holiday cottages are ideally located in the south west corner of the Isle of Anglesey (Sir Ynys Mon) in the village of Llangaffo; close to the beautiful beaches and pine forests of Newborough.</p>      
      <p>We look forward to hearing from you, contact Noel and Delyth Sumner  <a href="tel:+44 (0) 1248 440860">+44 (0) 1248 440860</a> or <a href="mailto:thehouseintheyard@gmail.com">thehouseintheyard@gmail.com.</a></p>            
      <p><Link to="/availability">Check Availability</Link> <Affiliates /></p>
    </section>
    <section className="explore">
      <img src={Anglesey} alt="Llanddwn Lighthouse" style={{width: `100%`}}/>
      <div>
        <h2>Explore the Isle of Anglesey</h2>
        <p>This beautiful Isle is steeped in history, boasts blue flag beaches, great restaurants and stunning landscapes. Anglesey is many things. It is a place that inspires, a place that appeals to all the senses, a place to see, hear, taste, smell and feel. It is a place to get away from it all. But most of all, Anglesey is a place to get out and do!</p>
        <p><Link to="/explore-anglesey">Explore</Link></p>
      </div>
    </section>
    <Testimonials />
  </Layout>
)

export default Home
