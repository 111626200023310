import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faTripadvisor } from "@fortawesome/free-brands-svg-icons";
import { Link } from "gatsby";


const Testimonials = () => (
    <Link to="/testimonials">
        <section className="testimonials">   
            <p style={{textAlign:`center`}}><FontAwesomeIcon icon={faTripadvisor}/></p>
            <h2 style={{textAlign:`center`}}>We're Proud of our Tripadvisor Reviews</h2>
            <p style={{textAlign:`center`}}>
                <FontAwesomeIcon icon={faStar}/>
                <FontAwesomeIcon icon={faStar}/> 
                <FontAwesomeIcon icon={faStar}/>
                <FontAwesomeIcon icon={faStar}/>
                <FontAwesomeIcon icon={faStar}/>        
            </p>
            <figure style={{marginTop: `20px`}}>
                <blockquote cite="https://www.tripadvisor.co.uk/ShowUserReviews-g552029-d8058995-r761085317-House_in_the_Yard_The_Farm_House-Newborough_Anglesey_North_Wales_Wales.html">
                    <h3>Wonderful location, beautiful accommodation and fabulous new owners!</h3>
                    <p>I’m reluctant to let people know how great House in the Yard is! A hidden gem on Anglesey. Beautiful interiors, spotlessly clean, fabulous garden, land to roam with your children and wonderful new hosts Delyth, Noel, Alys and Benji. We didn’t want to come home! It is so peaceful with wonderful views and a short drive to the stunning nature reserve and pine clad beach of Newborough. This is our second stay and we hope to return very soon. Highly recommended!</p>
                </blockquote>
                <figcaption><b> - July 2020, <cite>Tripadvisor</cite></b></figcaption>
            </figure> 
        </section>
    </Link>
    
)

export default Testimonials
